if (avastGlobals.web.locale !== 'ja-jp') {

	var testimonials = tns({
		"container": ".slider-testimonials",
		"controlsContainer": ".slider-testimonials-controls",
		"preventScrollOnTouch": "force",
		"controlsPosition": "bottom",
		"navPosition": "bottom",
		"gutter": 24,
		"slideBy": "page",
		"responsive": {
			"992": {
				"gutter": 32
			}
		}
	});

	// Create a slide counter bellow the Carousel
	avm.require('avast.web.slideCounter')(testimonials);
}
